import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
@Component({
  selector: 'app-links-page',
  templateUrl: './links-page.component.html',
  styleUrls: ['./links-page.component.css']
})
export class LinksPageComponent implements OnInit {

  apiToken:any=localStorage.getItem('token');
  MidOfficeLink="/login";
  constructor() {}
  ngOnInit(): void
  {
    $('.main-content').css('margin-left', '0px');
    $('#headerBloc').hide();
    $('#SideBarMenu').hide();
    $('#footerBloc').hide();
    if (this.apiToken.length>0)
    {
      this.MidOfficeLink=environment.MidOfficeUrl+"/?token="+this.apiToken;
    }
    else
    {
      window.location.href='/login';
    }
  }

}

