import {Component, OnInit} from '@angular/core';
import {GlobalService} from "../../shared/services/global.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Constantes} from "../../shared/constantes";
import {NgForm} from "@angular/forms";
import { Location } from '@angular/common';
import {NotificationService} from "../../shared/services/notification.service";
import {ProductsService} from "../../shared/services/products.service";
@Component({
  selector: 'app-description-new',
  templateUrl: './description-new.component.html',
  styleUrls: ['./description-new.component.css']
})
export class DescriptionNewComponent implements OnInit{
  readonly Constantes = Constantes;
  submitStatus:boolean=false;
  productFamillesDetail: any = [];
  bodyInvalid:boolean=false;
  Product: any = [];
  type: string = '';
  id: number|any;

  /*********** for generic description ********************/
  listOfCountry :any = [];
  listOfCityForCountry :any = [];
  oclub: any = [];
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  newDetailName:string="";
  channels: any = [];
  channelId:any=0;
  defaultChannel:any=Constantes.defaultChannel;
  /************************** CKEDITOR  *******************************/
  CKEDITOR: any;
  /************************** CKEDITOR  *******************************/
  /*********** for generic description ********************/
  constructor(
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private productService: ProductsService,
    private location: Location,
    private notification: NotificationService,
    ){}
  ngOnInit(): void {
    /************************** CKEDITOR  *******************************/
    delete CKEDITOR.instances["#bodyNew"];
    CKEDITOR.replace('bodyNew', {
      customConfig : '/assets/js/cke_custom_basic_dev_config_full.js',
      height : '350px'
    });
    /************************** CKEDITOR  *******************************/
    this.type = this.activatedRouter.snapshot.params['type'];
    this.id = this.activatedRouter.snapshot.params['id'];
    this.channelId = this.activatedRouter.snapshot.params['channelId'];
    if (!this.type)
    {
      this.router.navigate(['/' + this.type]);
    }
    /*********** for product info : get code and name ********/
    if (this.type && this.type !='generic-descriptions' && (this.id || !isNaN(this.id)))
    {
      this.getProduct();
    }
    /*********** for generic description ********************/
    if (this.type && this.type =='generic-descriptions')
    {
      this.getCountry();
      this.getOclubList();
    }
    this.getChannels();
    /*********** for generic description ********************/
    this.getProductFamillesDetail(this.channelId);
  }
  getChannels()
  {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => { console.log('channels',channels);
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getProductFamillesDetail(channelId:number)
  {
    this.productFamillesDetail=[];
    this.globalService.showSpinner();
    this.globalService.getParameterValue('', 'product_detail',true,'',channelId)
      .subscribe(
        productFamillesDetail => {
          this.globalService.hideSpinner();
          this.productFamillesDetail.push({'id':'','text':'famille'});
          for (let item in productFamillesDetail[0]['data'])
          {
            this.productFamillesDetail.push({'id':productFamillesDetail[0]['data'][item]['id'],'text':productFamillesDetail[0]['data'][item]['nameFr']});
          }
          if (channelId == this.defaultChannel)
          {
            // ajout la possiblité de rien choisir dans la famille si canal= generic
            this.productFamillesDetail.unshift({'id':0,'text':'Aucun'});
            console.log('this.productFamillesDetail =+>',this.productFamillesDetail);
          }
          console.log('this.productFamillesDetail =+>',this.productFamillesDetail);
        },
        error => {
          this.globalService.hideSpinner();
          this.globalService.checkApiError(error);
        }
      );
  }
/*********** for product info : get code and name ***********/
getProduct()
{
  this.globalService.showSpinner();
  this.productService.getProduct(this.id).subscribe(
    product => {
      this.Product = product;
    },
    error => {
      this.globalService.checkApiError(error);
      this.globalService.hideSpinner();
    }
  )
}
/*********** for generic description ********************/
  getCountry() {
  this.globalService.getCountry()
    .subscribe(
      Localities => { console.log('==> Country ==>  ',Localities);
        for (let countryItem in Localities[0]['data'])
        {
          if (Localities[0]['data'][countryItem]['type']['code']== 'country')
          {
            this.listOfCountry.push({'id':Localities[0]['data'][countryItem]['id'],'text':Localities[0]['data'][countryItem]['nameFr']+' ('+Localities[0]['data'][countryItem]['code']+')'});
          }
        }
      },
      error => {
        this.globalService.checkApiError(error);
      }
    );
}
  getCityOfCountry(mainParentId:any)
  {
    $('.hideThis').show();
    $('#citys').prop("disabled", false);

    this.globalService.getCityOfCountryByID(mainParentId)
      .subscribe(
        CityOfCountry => {  console.log('CityOfCountry ==> ',CityOfCountry);
          this.listOfCityForCountry=[];
          for (let cityItem in CityOfCountry[0]['data'])
          {
            if (CityOfCountry[0]['data'][cityItem]['type']['code']== 'city')
            {
              this.listOfCityForCountry.push({'id':CityOfCountry[0]['data'][cityItem]['id'],'text':CityOfCountry[0]['data'][cityItem]['nameFr']});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getOclubList() {
    this.globalService.getParameterValue('oclub_group', '')
      .subscribe(
        oclub => {
          for (let item in oclub[0]['data']) {
            this.oclub.push(oclub[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
/*********** for generic description ********************/
  onSubmit(form:NgForm)
  {
    let bodyContent= CKEDITOR.instances["bodyNew"].getData();
    let entity='product';
    if (bodyContent.length < 5)
    {
      this.bodyInvalid = true;
    }
    else
    {
      this.bodyInvalid = false;
    }
    if (form.valid && bodyContent.length > 5 && this.type.length)
    {
      this.globalService.showSpinner();
      let titleFr = form.value.title;
      let typeId=form.value.family;
      let rank=form.value.rank;
      let channels=form.value.channels;
      if (isNaN(rank))
      {
        rank=null;
      }
      let objectIdentity=this.id;
      let oclub=null;
      if (this.type == 'generic-descriptions')
      {
        entity='locality';
        oclub=form.value.oclub
        /************************** locality manager ******************************/

        let countrys=form.value.countrys;
        let citys=form.value.citys;
        console.log('citys = ',citys);
        console.log('countrys = ',countrys);
        if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length>0))
        {
          objectIdentity=citys;
        }
        else if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length==0) )
        {
          objectIdentity=countrys;
        }

        else if ((countrys !== null && countrys.length>0) && (citys == null) )
        {
          objectIdentity=countrys;
        }
        else
        {
          objectIdentity='';
        }

        console.log('selected locality for search= ',objectIdentity);

        /************************** locality manager *****************************/

      }
      if (typeId == 0)
      {
        typeId = null;
      }
      this.globalService.addEditDetails(entity, objectIdentity, titleFr, bodyContent, typeId, rank,null,null,channels)
      .subscribe(
        response => {
          if (!isNaN(response['id']))
          {
            this.newDetailName=response['titleFr'];
            this.submitStatus=true;
            let location=this.location;
            this.globalService.hideSpinner();
            this.notification.showSuccess("Le détail   '" +this.newDetailName+ "'   a été ajouté avec succès","");
            setTimeout(function()
            {
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }, 2200);
            setTimeout(function()
            {
               location.back();
            }, 2200);
          }
        },
        error => {
          console.log('error ==>',error);
          this.globalService.hideSpinner();
          this.notification.showError(error['error']['hydra:description'],"");
        }
      );
    }

  }
  goBack()
  {
    this.globalService.goBack();
  }
}
