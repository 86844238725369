import { Injectable } from '@angular/core';

@Injectable()
export class Constantes
{
  public static apiKey='5235d149-70fae11c-9bd18b60-e06125b7-95a49d3b-afb1b648';
  public static defaultChannel=localStorage.getItem('generic');
  public static defaultChannelCode='generic';
  public static codeMaxlength=50;
  public static nameMaxlength=100;
  public static maxItemsPerPage=10;
  public static maxFileSize = 200 * 1024; //1000ko
  public static maxFileSizeHtml = Constantes.maxFileSize/1024+' ko'; //1000ko
  public static FileUploaderConfig={
    url: '/',
    disableMultipart : false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    maxFileSize:Constantes.maxFileSize,
    allowedFileType: ['image']
  }
  public static editorConfig={
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    sanitize: false, // must be  allways at false
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage'],
      ['insertVideo']
    ]
  };
}
