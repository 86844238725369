import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../../shared/services/products.service";
import {NgForm,FormControl} from '@angular/forms';
import {debounceTime, tap, map } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  nbProducts=0;
  firstPage=0;
  nextPage=0;
  lastPage=0;
  page: number = 1;
  productsLists: any = [];
  productsNameLists: any = [];
  productsLocality :any = [];
  ElementId:number =0;
  listOfCountry :any = [];
  listOfCityForCountry :any = [];
  ProductSubTypes: any = [];
  searchProductName = new FormControl();
  filteredProducts: any;
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  currentPage=1;
  parentCode='accommodation';
  maxItem=Constantes.maxItemsPerPage;
  constructor(
    private productService: ProductsService,
    private globalService: GlobalService,
    private notification: NotificationService) {}
  ngOnInit(): void
  {
    this.getAllProducts();
    this.getCountry();
    this.getProductSubTypes();
    this.GetProductName ();
  }
  getAllProducts()
  {
    this.globalService.showSpinner();
    this.productsLists=[];
    this.productService.getAllProducts('accommodation').subscribe(
      productsLists => {
        this.productsLists = productsLists[0]['data'];
        this.nbProducts=0;
        this.lastPage=0;
        this.nbProducts =productsLists[0]['info'][0]['totalItems'];
        this.lastPage=Math.ceil(this.nbProducts/this.maxItem);
        this.globalService.hideSpinner();
        console.log('productsLists ===========>',this.productsLists);
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }
  displayFn: any;
  getCountry() {
    this.productService.getProductLocalities()
      .subscribe(
        Localities => {
          for (let countryItem in Localities)
          {
            if (Localities[countryItem]['type_code']== 'country')
            {
              this.listOfCountry.push({'id':Localities[countryItem]['code'],'text':Localities[countryItem]['name_fr']+' ('+Localities[countryItem]['code']+')'});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getCityOfCountry(mainParentCode:any)
  {
    $('.hideThis').show();
    $('#citys').prop("disabled", false);
    this.globalService.getCityOfCountryByCode(mainParentCode)
      .subscribe(
        CityOfCountry => {
          this.listOfCityForCountry=[];
          for (let cityItem in CityOfCountry[0]['data'])
          {
            if (CityOfCountry[0]['data'][cityItem]['type']['code']== 'city')
            {
              this.listOfCityForCountry.push({'id':CityOfCountry[0]['data'][cityItem]['code'],'text':CityOfCountry[0]['data'][cityItem]['nameFr']});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getProductSubTypes() {
    this.globalService.getParameterValue (this.parentCode,'product_subtype')
      .subscribe(
        ProductSubTypes => {
          for (let item in ProductSubTypes[0]['data']) {
              this.ProductSubTypes.push(ProductSubTypes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  GetProductName ()
  {
    this.searchProductName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredProducts = [];
          this.isLoading = true;
        }),
        map((productNameFr: string) =>productNameFr.toString().trim())
      )
      .subscribe((productNameFr:string) => {
        this.productService.searchProductsName(productNameFr,'accommodation').subscribe(
          searchResponse =>
          {
            if (searchResponse[0]!== undefined)
            {
              this.isLoading = false;
              this.productsNameLists=searchResponse[0]['data'];
            }
          },
          error => {
            this.globalService.checkApiError(error);
          }
        )

      }
      );

  }
  searchProducts(form: NgForm,page=1)
  {
    this.globalService.showSpinner();
    let code=form.value.code;
    let name=this.searchProductName.value;
    let etat=form.value.etat;

    let locality='';
    let countrys=form.value.countrys;
    let citys=form.value.citys;
    console.log('citys = ',citys)
    console.log('countrys = ',countrys)
    if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length>0))
    {
      locality=citys;
    }
    else if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length==0) )
    {
      locality=countrys;
    }

    else if ((countrys !== null && countrys.length>0) && (citys == null) )
    {
      locality=countrys;
    }
    else
    {
      locality='';
    }



    //let sub_type=form.value.sub_type;
    let sub_type=null;
    let type='accommodation';
    this.productsLists = [];
    this.productService.searchProducts(page,code,name,etat,locality,sub_type,type).subscribe(
      searchResponse =>
      {
        this.productsLists=searchResponse[0]['data'];
        this.lastPage=0;
        this.nbProducts=0;
        this.nbProducts =searchResponse[0]['info'][0]['totalItems'];
        this.lastPage=Math.ceil(this.nbProducts/Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.productsLists.slice();
    if (!sort.active || sort.direction === '') {
      this.productsLists= data;
      return;
    }

    this.productsLists= data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'nameFr':
          return this.compare(a.nameFr, b.nameFr, isAsc);
        case 'productLocalities':
          return this.compare(a.productLocalities, b.productLocalities, isAsc);
        case 'createdAt':
          return this.compare(a.createdAt, b.createdAt, isAsc);
        case 'active':
          return this.compare(a.isActive, b.isActive, isAsc);
        case 'providerCode':
          return this.compare(a.providerCode, b.providerCode, isAsc);
        default:
          return 0;
      }
    });
  }
  pagesCounter(i: number) {
     return new Array(i);
  }
  loadNextPage(form: NgForm,pageNumber:number)
  {
    this.currentPage=pageNumber;
    if (pageNumber<=this.lastPage && pageNumber >0)
    {
      this.searchProducts(form,pageNumber);
    }
  }
  duplicateProduct(product:any)
  {
    let This=this;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir dupliquer",product.nameFr,"Continuer","Dupliquer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        /*************************** code produit à dupliquer *******************************/
        $('#duplicationModal').show();
        $('#duplicationModal').addClass('show');
        $("#btnDiplicateCode").click(function(){
          $('#duplicationModal').hide();
          $('#duplicationModal').removeClass('show');
          let newDuplicatCode:any=$('#newDuplicatCode').val();
          if (newDuplicatCode.length >3 )
          {
            /************************** TO DO ***********************************************/
        This.globalService.showSpinner();
        This.productService.duplicateProduct(product.id,newDuplicatCode).subscribe(
          response => {
            console.log('duplicate response',response);
            This.globalService.hideSpinner();
            if (response.message=='OK')
            {
              This.notification.showSuccess("L'hôtel ' "+product.nameFr+" ' a été dupliqué avec succès","");
              This.getAllProducts();
              let editUrl="/product/"+response.data.id+"/"+response.data.code+"/generalites/edit";
              setTimeout(function()
              {
                $("html, body").animate({ scrollTop: 0 }, "slow");
              }, 2200);
              setTimeout(function()
              {
                window.location.href=editUrl;
              }, 2200);
            }
            else
            {
              This.notification.showError(response.message,"");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            This.globalService.hideSpinner();
            This.globalService.checkApiError(error);
            This.globalService.hideSpinner();
            This.notification.showError(error['error']['hydra:description'],"");
          }
        );
            /************************** TO DO ***********************************************/
          }
        });
        /*************************** code produit à dupliquer *******************************/

      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  EditProductArchived(product:any)
  {
    let This=this;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir archiver ",product.nameFr,"Valider","Archiver !","", function (confirmed:any)
    {
      if (confirmed)
      {
        This.globalService.showSpinner();
        This.productService.EditProductArchived(product.id).subscribe(
          response => {
            console.log('archivated  response',response);
            This.globalService.hideSpinner();
            if (response['id'])
            {
              This.notification.showSuccess("L'hôtel ' "+product.nameFr+" ' a été archivé avec succès","");
              This.getAllProducts();
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            This.globalService.hideSpinner();
            This.globalService.checkApiError(error);
            This.globalService.hideSpinner();
            This.notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
  checkValid(form: NgForm)
  {
    let code=form.value.code;
    let name=this.searchProductName.value;
    let countrys=form.value.countrys;
    if (code !== "" || name !== "" || countrys !== "")
    {
      return false;
    }
    else
    {
      return true;
    }
    return true;
  }
  closeModalDuplicateModal()
  {
    /**************** hide Duplicated product Modal************************/
    console.log('click');
    $('.modalDuplicate').hide();
    $('.modalDuplicate').removeClass('show');
    /**************** hide Duplicated product Modal************************/
  }
}
