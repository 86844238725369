<div class="content-wrapper">
  <div class="row"></div>
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Liste des forfaits</h6></div>
            <div class="card-header">
              <h4 class="card-title float-left product-count">{{nbPackages}} forfait<span *ngIf="nbPackages >1">s</span></h4>
              <a routerLink="/package/new" title="Nouveau Package">
                <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right"   type="button">
                  <i class="ft-plus mr-1"></i>Nouveau forfait
                </button>
              </a>
            </div>
            <div *ngIf="packagesList" class="card-content">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        <form #SearchEngineForm="ngForm" (ngSubmit)="searchPackages(SearchEngineForm);">
                          <!-- ligne 1 -->
                          <div class="row" style="display: flex!important;">
                            <!-- ID -->
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label for="code">Code forfait</label>
                                <input class="form-control capitalize" id="code" name="code" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Nom -->
                            <div class="col-md-4">
                              <div class="form-floating">
                                <mat-form-field>
                                  <label for="name">Nom</label>
                                  <input [formControl]="searchPackageName" [matAutocomplete]="auto" aria-label="State"   class="form-control autocompleteInput" id="name" matInput name="name" ngModel type="text" value="">
                                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                                    <ng-container *ngIf="!isLoading">
                                      <mat-option *ngFor="let product of packagesNameLists" [value]="product.nameFr">
                                        <span>{{product.nameFr}}</span>
                                      </mat-option>
                                    </ng-container>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- Localité-->
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label for="codeHotel">code produit</label>
                                <input class="form-control" id="codeHotel" name="codeHotel" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Localité
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label>Localité</label>
                                <img *ngIf="localitiesOfAllPackages?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                                <ng-select2 id="Localitys"  name="Localitys"  ngModel *ngIf="localitiesOfAllPackages?.length != 0" [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Pays / ville'" [data]="localitiesOfAllPackages" class="form-control ng-select2" [width]="value"></ng-select2>
                              </div>
                            </div--->
                            <!-- Etat -->
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label for="etat">Etat</label>
                                <select aria-label="Floating label select example" class="form-control" id="etat"   name="etat" ngModel>
                                  <option selected="" value="">Tous</option>
                                  <option value="1">Actif</option>
                                  <option value="0">Inactif</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label>&nbsp;</label>
                                <div class="w-auto d-block">
                                  <button class="btn btn-primary" type="submit">
                                    <i class="ft-search mr-1"></i>Rechercher
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
                <div class="table-responsive" *ngIf="packagesList?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row mt-2 tableList" matSort (matSortChange)="sortData($event)">
                    <thead >
                    <tr>
                      <th mat-sort-header="id" style="width: 3% !important;">Id</th>
                      <th mat-sort-header="code" id="codeForfait">Code forfait</th>
                      <th mat-sort-header="nameFr" style="width:auto !important;min-width: 272px!important;">Nom</th>
                      <th mat-sort-header="type" style="width: 15% !important;">Codes produits</th>
                      <th mat-sort-header="localities" style="width: 15% !important;">Pays</th>
                      <th mat-sort-header="ville" style="width: 15% !important;">Ville</th>
                      <th mat-sort-header="durationNights" style="width: 5% !important;">Nuits/Jours</th>
                      <th mat-sort-header="active" style="width: 6% !important;">Etat</th>
                      <th style="width: 14% !important;">Actions</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="packagesList?.length == 0">
                    <img class="LoaderImages" src="../assets/img/load.gif" style="position: inherit; margin: 15px;">
                    </tbody>
                    <tbody *ngIf="packagesList?.length != 0">
                    <tr *ngFor="let item of packagesList">
                      <td style="width: 3% !important;" class="text-center"><a style="color: #342E49;" routerLink="/package/{{item.id}}/{{item.code}}/generalites/edit"  title="Editer">{{item.id}}</a></td>
                      <td style="width: 12% !important; cursor: pointer;min-width: 52px;">{{item.code}}</td>
                      <td style="width: auto !important;min-width: 272px!important;">{{item.name}}</td>
                      <td  style="width: 15% !important;">
                        <span class="listOverFlow">
                          <li *ngFor="let product of item.accommodations">
                            {{product.code}}
                          </li>
                        </span>
                      </td>
                      <td style="width: 15% !important;">
                         <span  *ngFor="let country of item.localities">
                          <span *ngIf="country.type_code=='country'">
                            {{country.name_fr}}
                          </span>
                         </span>
                      </td>
                      <td style="width: 15% !important;">
                         <span  *ngFor="let city of item.localities">
                          <span *ngIf="city.type_code=='city'">
                            {{city.name_fr}}
                          </span>
                         </span>
                      </td>
                      <td class="text-center" style="width: 5% !important;"><span *ngIf="item.duration_nights<10">0</span>{{item.duration_nights}}/<span *ngIf="item.duration_days<10">0</span>{{item.duration_days}}</td>
                      <td *ngIf="!item.is_active" style="width: 6% !important;text-align: center;">
                        <i class=" fa-solid fa-ban icon-danger Incatif" title="Incatif "></i>
                      </td>
                      <td *ngIf="item.is_active"  style="width: 6% !important;text-align: center;">
                        <i class=" fa-solid fa-check icon-success Actif" title="Actif"></i>
                      </td>
                      <td style="width: 14% !important;text-align: left;padding-left: 0px !important;" class="actionMobile">
                        <a routerLink="/package/{{item.id}}/{{item.code}}/generalites/edit"  title="Editer">
                          <i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin: 5px; "></i>
                        </a>
                        <!----a routerLink="/package/{{item.id}}/{{item.code}}/descriptions" target="_blank">
                          <i class="fas fa-list btnActionDescriptions" style="margin: 5px; color:black;" title="Les descriptifs"></i>
                        </a>
                        <a routerLink="/package/{{item.id}}/{{item.code}}/photos" target="_blank">
                          <i class="fas fa-images btnActionPhotos" style="margin: 5px; " title="Les photos"></i>
                        </a>
                        <a routerLink="/package/{{item.id}}/{{item.code}}/tags" target="_blank">
                          <i class="fa-duotone fa-tags btnActionTag" style="margin: 5px; " title="Les tags"></i>
                        </a---->
                        <i class="fa-regular ft-archive btnActionArchive" style="margin: 5px;cursor:pointer;" (click)="EditProductArchived(item);"  title="Archiver"></i>
                        <i class="fas fa-copy duplication-alert btnActionDuplicate" (click)="duplicateProduct(item);" style="margin: 5px; cursor:pointer;"  title="Dupliquer"></i>
                        <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(item.id);" data-toggle="modal" data-target="#editLogModal"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nbPackages > maxItem">
                    <div align="right" class="row BlocPaginate">
                      <div class="pagination ngx-pagination">
                        <ngb-pagination [collectionSize]="nbPackages" (pageChange)="loadNextPage(SearchEngineForm,$event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                          <ng-template ngbPaginationPrevious>Précédent</ng-template>
                          <ng-template ngbPaginationNext>Suivant</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- Modal log -->
<div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <app-log-list [ElementId]="ElementId"></app-log-list>
</div>
<!-- Modal duplication -->
<div class="modal fade in editLogModal modalDuplicate" id="duplicationPackageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog editParameterModalDialog" role="document">
    <div class="modal-content editParameterModalContent">
      <form  method="post" name="parameter" #duplicatForm="ngForm"  >
        <div class="modal-body seoCard">
          <!------------------------------------------------------------------------------------------------>
          <div class="card-body mt-0 pt-0">
            <div class="invoice-title mr-0 pr-0 mb-0">
              <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">Veuillez saisir le nouveau code :</h4>
            </div>
            <div class="row mb-1">
              <div class="row mb-3">
                <!-- code -->
                <div class="col-lg-4 mb-2 pr-2">
                  <label for="code">Code <span class="etoile">*</span></label>
                  <input style="min-width: 255px;text-transform: uppercase;" [ngClass]="{'is-invalid' : !newDuplicatPackageCode.valid && !newDuplicatPackageCode.pristine}" class="form-control" id="newDuplicatPackageCode" maxlength="30" name="newDuplicatPackageCode" ngModel placeholder="Nouveau code" #newDuplicatPackageCode="ngModel"   required minlength="3" type="text">
                </div>
              </div>
            </div>
          </div>
          <!------------------------------------------------------------------------------------------------>
        </div>
        <div class="modal-footer pt-3 w-100">
          <button type="button" class="btn btn-secondary mr-2 closeDiplicateModal" (click)="closeModalDuplicateModal()" data-dismiss="modal">Annuler</button>
          <button id="btnDiplicatePackageCode" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="button" [disabled]="!duplicatForm.form.valid">
            <i class="ft-save"></i> Valider
          </button>
        </div>
      </form>
    </div>
  </div>

</div>

