<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-sm-flex align-items-center justify-content-between infoBar">
            <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
              <span class="badge badge-soft-dark" *ngIf="type && type=='generic-descriptions'">Modification {{title.value}}</span>
              <span class="badge badge-soft-dark" *ngIf="type && type!='generic-descriptions'">Modification descriptif  {{Product.code}} {{Product.nameFr}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <div class="mb-4">
                <!----h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle" *ngIf="title.value?.length>0">{{title.value}}</h4---->
              </div>
            </div>
            <div class="card desciptionCard" >
              <form method="post" name="objectDetails" #objectDetailsForm="ngForm" (ngSubmit)="onSubmit(objectDetailsForm);">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="title">Titre<span class="etoile">*</span></label>
                      <input type="text" [ngClass]="{'is-invalid' : title.invalid  && (title.dirty || title.touched)}" #title="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  [(ngModel)]="description.titleFr && description.titleFr" class="form-control" id="title" name="title" placeholder="Titre">
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label" for="channels">Canal</label>
                      <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <select ngModel *ngIf="channels?.length != 0" #channelID="ngModel" (change)="getProductFamillesDetail(channelID.value)" [(ngModel)]="(description?.id>0 && type && type !='generic-descriptions' && description.channel.id) && description.channel.id||defaultChannel" aria-label="Floating label select example" class="form-control" id="channels" name="channels">
                        <option *ngFor="let channel of channels" class="textCapitalise"  value="{{channel.id}}">{{channel.nameFr}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-3">
                    <div class="mb-3">
                      <label for="family">Famille</label>
                      <ng-select2 class="textCapitalise form-control ng-select2"  [ngClass]="{'is-invalid' : family.invalid  && (family.dirty || family.touched)}"  #family="ngModel"   ngModel id="family"  name="family"  *ngIf="productFamillesDetail?.length != 0" [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Famille'" [data]="productFamillesDetail" [width]="value" [(ngModel)]="(productFamillesDetail?.length>0 && description?.type.id) && description.type.id"></ng-select2>
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-1 hidden-xs">
                    <div class="mb-3">
                      <label for="rank" style="visibility: hidden">Ordre</label>
                      <input type="number" style="visibility: hidden" [ngClass]="{'is-invalid' : rank.invalid  && (rank.dirty || rank.touched)}" min="0" step="1" #rank="ngModel" class="form-control" name="rank" id="rank" [(ngModel)]="description.rank && description.rank">
                    </div>
                  </div>
                  <!-- Pays-->
                  <div class="col-md-2" *ngIf="type && type =='generic-descriptions'" >
                    <div class="form-floating mb-3">
                      <label>Pays<span class="etoile">*</span></label>
                      <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <ng-select2 id="countrys"  name="countrys" [ngClass]="{'is-invalid' : countrys.invalid  && (countrys.dirty || countrys.touched)}"  #countrys="ngModel"  [attr.required]="true" ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2" [width]="value" [(ngModel)]="description.locality && localiteParentIDOfDetail"></ng-select2>
                    </div>
                  </div>
                  <!-- Ville-->
                  <div class="col-md-2" *ngIf="type && type =='generic-descriptions'" >
                    <div class="form-floating mb-3">
                      <label>Villes</label>
                      <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                      <ng-select2 id="citys"  name="citys" [ngClass]="{'is-invalid' : citys.invalid  && (citys.dirty || citys.touched)}"  #citys="ngModel"  [attr.required]="true" ngModel *ngIf="listOfCityForCountry?.length != 0" [disabled]="disabled" [allowClear]="allowClear" [placeholder]="'Villes'"  [data]="listOfCityForCountry" class="form-control ng-select2" [width]="value" [(ngModel)]="description.locality && description?.locality.id"></ng-select2>
                    </div>
                  </div>
                  <div *ngIf="type && type =='generic-descriptions'" class="col-md-2">
                    <div class="form-floating">
                      <label for="oclub">Ôclub ?</label>
                      <img *ngIf="oclub?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <select *ngIf="oclub?.length != 0" aria-label="Floating label select example" class="form-control" id="oclub" name="oclub" ngModel  [(ngModel)]="description.oclub && description?.oclub.id" >
                        <option value=""></option>
                        <option *ngFor="let item of oclub"  class="textCapitalise" value="{{item.id}}">{{item.nameFr}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="mb-3">
                      <label for="body">Description<span class="etoile">*</span></label>
                      <div [ngClass]="{'isInvalid' : bodyInvalid}">
                        <textarea ngModel  id="body" name="body" placeholder="Description..." ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="mb-3" style="font-size: smaller;">
                    <div class="d-flex justify-content-start">
                      Création : &nbsp;<span class="badge badge-soft-info">{{description.createdAt| date :  "dd/MM/yyyy à HH:mm"}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="mb-3" style="font-size: smaller;">
                    <!---div class="d-flex justify-content-start" *ngIf="description.updatedAt">
                      Modification : &nbsp;<span class="badge badge-soft-info">{{description.updatedAt| date :  "dd/MM/yyyy à HH:mm"}}</span>
                    </div--->
                  </div>
                </div>
                <div class="col-lg-6 float-end">
                  <div class="d-flex flex-wrap gap-2 justify-content-end">
                    <button class="btn btn-info waves-effect btn-label waves-light modal-wait blocRightBottom mr-1 text-center" type="button" (click)="GlobalService.goBack();">
                      <i class="ft-arrow-left"></i> Retour
                    </button>
                    <button [disabled]="objectDetailsForm.invalid || productFamillesDetail?.length == 0" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit">
                      <i class="ft-save"></i> Enregistrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
