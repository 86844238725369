<div class="content-wrapper">
  <div class="row"></div>
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Liste des produits</h6></div>
            <div class="card-header">
              <h4 class="card-title float-left product-count">{{nbProducts}} produit<span *ngIf="nbProducts>1">s</span></h4>
              <a routerLink="/product/new" title="Nouveau produit">
                <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right" type="button">
                  <i class="ft-plus mr-1"></i>Nouveau produit </button>
              </a>
            </div>
            <div *ngIf="productsLists" class="card-content ">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        <form #SearchEngineForm="ngForm" (ngSubmit)="searchProducts(SearchEngineForm,1);">
                          <!-- ligne 1 -->
                          <div class="row mb-3" style="display: flex!important;">
                            <!-- Code -->
                            <div class="col-md-2">
                              <div class="form-floating mb-0">
                                <label for="code">Code produit</label>
                                <input class="form-control capitalize" id="code" name="code" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Nom -->
                            <div class="col-md-3">
                              <div class="form-floating mb-0">
                                <mat-form-field>
                                  <label for="name">Nom produit</label>
                                  <input [formControl]="searchProductName"  [matAutocomplete]="auto" aria-label="State" class="form-control autocompleteInput" id="name" matInput name="name" ngModel type="text" value="">
                                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngIf="isLoading" class="is-loading">...</mat-option>
                                    <ng-container *ngIf="!isLoading">
                                      <mat-option *ngFor="let product of productsNameLists"  [value]="product.nameFr">
                                        <span>{{product.nameFr}}</span>
                                      </mat-option>
                                    </ng-container>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- Pays-->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label>Pays</label>
                                <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                                <ng-select2 id="countrys"  name="countrys"  ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2 Localitys" [width]="value"></ng-select2>
                              </div>
                            </div>
                            <!-- Ville-->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label>Villes</label>
                                <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                                <ng-select2 id="citys" name="citys"  ngModel  [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Villes'"  [data]="listOfCityForCountry" class="form-control ng-select2 Localitys" [width]="value"></ng-select2>
                              </div>
                            </div>
                            <!-- Sous type
                            <div class="col-md-2">
                              <div class="form-floating mb-0">
                                <label for="sub_type">Type</label>
                                <img *ngIf="ProductSubTypes?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                                <select *ngIf="ProductSubTypes?.length != 0" aria-label="Floating label select example" class="form-control" id="sub_type" name="sub_type" ngModel>
                                  <option selected="" value="">Tous</option>
                                  <option *ngFor="let ProductSubType of ProductSubTypes" class="textCapitalise" value="{{ProductSubType.code}}">{{ProductSubType.nameFr}}</option>
                                </select>
                              </div>
                            </div>-->
                            <!-- Etat -->
                            <div class="col-md-1">
                              <div class="form-floating mb-0">
                                <label for="etat">Etat</label>
                                <select aria-label="Floating label select example" class="form-control" id="etat" name="etat" ngModel>
                                  <option selected="" value="">Tous</option>
                                  <option value="true">Actif</option>
                                  <option value="false">Inactif</option>
                                </select>
                              </div>
                            </div>
                          <div class="col-md-2 blocSearch">
                            <div class="form-floating mb-0">
                              <button class="btn btn-primary" type="submit" >
                                <i class="ft-search mr-1"></i>Rechercher </button>
                            </div>
                          </div>
                          </div>
                          <!-- .ligne 2 -->
                          <!-- Submit + products count -->
                          <div class="row blocBtnSearch">
                            <div class="col-md-9">
                              <div class="d-flex justify-content-start product-count"></div>
                            </div>

                          </div>
                          <input type="hidden" value="accommodation"  id="type" name="type" ngModel>
                        </form>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
                <div class="table-responsive" *ngIf="productsLists?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row" matSort (matSortChange)="sortData($event)">
                    <thead>
                    <tr>
                      <th mat-sort-header="id" style="width:5% !important;">ID</th>
                      <th mat-sort-header="code" style="width: 15% !important;">Code produit</th>
                      <th mat-sort-header="nameFr" style="width: auto !important;min-width: 272px!important;">Nom produit</th>
                      <th mat-sort-header="productLocalities" style="width: 15% !important;">Pays</th>
                      <th style="width: 15% !important;">ville</th>
                      <!---th mat-sort-header="createdAt" style="width: 13% !important;">Date de création</th--->
                      <th mat-sort-header="active" style="width: 7% !important;">Etat</th>
                      <th style="width: 16% !important;">Actions</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="productsLists?.length != 0">
                    <tr *ngFor="let product of productsLists">
                      <td style="width: 5% !important;font-weight: 500;" class="text-center"><a style="color: #342E49;"  routerLink="/product/{{product.id}}/{{product.code}}/generalites/edit" title="Editer"  >{{product.id}}</a></td>
                      <td style="width: 15% !important;">{{product.code}}</td>
                      <td style="width: auto !important;min-width: 272px!important;">{{product.nameFr}}</td>
                      <td style="width: 15% !important;">
                         <span  *ngFor="let item of product.productLocalities">
                          <span *ngIf="item.locality.type.code=='country'">
                            {{item.locality.nameFr}}
                          </span>
                         </span>

                      </td>
                      <td style="width: 15% !important;">
                         <span  *ngFor="let item of product.productLocalities">
                          <span *ngIf="item.locality.type.code=='city'">
                            {{item.locality.nameFr}}
                          </span>
                         </span>
                      </td>
                      <!---td style="width: 13% !important;"  class="text-center">{{product.createdAt| date :  "dd/MM/yyyy à HH:mm"}}</td--->
                      <td *ngIf="!product.isActive" style="width: 7% !important;">
                        <i class=" fa-solid fa-ban icon-danger Incatif" title="Incatif"></i>
                      </td>
                      <td *ngIf="product.isActive"  style="width: 7% !important;">
                        <i class=" fa-solid fa-check icon-success Actif" title="Actif"></i>
                      </td>
                      <td style="width: 16% !important;padding-left: 0px !important;" class="actionMobile">
                        <a routerLink="/product/{{product.id}}/{{product.code}}/generalites/edit" title="Editer"  >
                          <i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin: 5px; "></i>
                        </a>
                        <!---a routerLink="/product/{{product.id}}/{{product.code}}/descriptions" target="_blank">
                          <i class="fas fa-list btnActionDescriptions" style="margin: 5px;" title="Les descriptifs"></i>
                        </a>
                        <a routerLink="/product/{{product.id}}/{{product.code}}/photos" target="_blank">
                          <i class="fas fa-images btnActionPhotos" style="margin: 5px; " title="Les photos"></i>
                        </a
                        <a routerLink="/product/{{product.id}}/{{product.code}}/packages" target="_blank">
                          <i class="fas fa-cubes btnActionPackages" style="margin: 5px; " title="Les forfaits"></i>
                        </a---->
                          <i class="fa-regular ft-archive btnActionArchive" style="margin: 5px;cursor:pointer;" (click)="EditProductArchived(product);"  title="Archiver"></i>
                        <i class="fas fa-copy duplication-alert btnActionDuplicate" style="margin: 5px; cursor:pointer;" (click)="duplicateProduct(product);" title="Dupliquer"></i>
                        <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(product.id);" data-toggle="modal" data-target="#editLogModal"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nbProducts> maxItem">
                    <div align="right" class="row BlocPaginate">
                      <div class="pagination ngx-pagination">
                        <ngb-pagination [collectionSize]="nbProducts" (pageChange)="loadNextPage(SearchEngineForm,$event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                          <ng-template ngbPaginationPrevious>Précédent</ng-template>
                          <ng-template ngbPaginationNext>Suivant</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- Modal log -->
<div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <app-log-list [ElementId]="ElementId"></app-log-list>
</div>
<!-- Modal duplication -->
<div class="modal fade in editLogModal modalDuplicate" id="duplicationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog editParameterModalDialog" role="document">
    <div class="modal-content editParameterModalContent">
      <form  method="post" name="parameter" #duplicatForm="ngForm"  >
        <div class="modal-body seoCard">
          <!------------------------------------------------------------------------------------------------>
          <div class="card-body mt-0 pt-0">
            <div class="invoice-title mr-0 pr-0 mb-0">
              <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">Veuillez saisir le nouveau code :</h4>
            </div>
            <div class="row mb-1">
              <div class="row mb-3">
                <!-- code -->
                <div class="col-lg-4 mb-2 pr-2">
                  <label for="code">Code <span class="etoile">*</span></label>
                  <input style="min-width: 255px;text-transform: uppercase;" [ngClass]="{'is-invalid' : !newDuplicatCode.valid && !newDuplicatCode.pristine}" class="form-control" id="newDuplicatCode" maxlength="30" name="newDuplicatCode" ngModel placeholder="Nouveau code" #newDuplicatCode="ngModel"   required minlength="3" type="text">
                </div>
              </div>
            </div>
          </div>
          <!------------------------------------------------------------------------------------------------>
        </div>
        <div class="modal-footer pt-3 w-100">
          <button type="button" class="btn btn-secondary mr-2 closeDiplicateModal" (click)="closeModalDuplicateModal()" data-dismiss="modal">Annuler</button>
          <button id="btnDiplicateCode" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="button" [disabled]="!duplicatForm.form.valid">
            <i class="ft-save"></i> Valider
          </button>
        </div>
      </form>
    </div>
  </div>

</div>

