<div class="app-sidebar menu-fixed" data-background-color="man-of-steel" data-scroll-to-active="true" id="SideBarMenu">
  <div class="sidebar-header">
    <div class="logo clearfix"><a class="logo-text float-left" routerLink="/">
      <div class="logo-img"><img src="../assets/img/logo.png" alt="Maxula Travel" class="imgLogo"/></div></a>
      <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;"><i class="ft-x"></i></a>
    </div>
  </div>
  <div class="sidebar-content main-menu-content">
    <div class="nav-container">
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li class="has-sub nav-item">
          <ul class="menu-content" style="display: block !important;">
            <li [ngClass]="{'active': currentRoute=='/'}"><a routerLink="/" class="sideBarLink" title="Tableau de bord"><i class="ft-home"></i><span class="menu-title" data-i18n="Data Tables">Tableau de bord</span></a></li>
            <li [ngClass]="{'active': currentRoute.includes('/product') && !currentRoute.includes('/parameter')  && !currentRoute.includes('/archives')}"><a routerLink="/products"  class="sideBarLink" title="Liste des produits"><i class="ft-shopping-bag"></i><span class="menu-item" data-i18n="API">Produits</span></a> </li>
            <li [ngClass]="{'active': currentRoute.includes('/package') && !currentRoute.includes('/product')  && !currentRoute.includes('/archives')}"><a routerLink="/packages" class="sideBarLink" title="Liste des forfaits"><i class="ft-package"></i><span class="menu-item" data-i18n="API">Forfaits</span></a> </li>
            <li [ngClass]="{'active': currentRoute.includes('/archives')}"><a routerLink="/archives" class="sideBarLink" title="Liste des produits archivés"><i class="ft-archive"></i><span class="menu-item" data-i18n="API">Archives</span></a> </li>
            <li [ngClass]="{'active': currentRoute.includes('/produits-manquants')}"><a routerLink="/produits-manquants" class="sideBarLink" title="Liste des produits manquants"><i class="ft-layers"></i><span class="menu-item" data-i18n="API">Produits non importés</span></a> </li>
            <li [ngClass]="{'active': currentRoute.includes('/generic-description')}"><a routerLink="/generic-descriptions" class="sideBarLink" title="Liste des descriptifs génériques"><i class="ft-book-open"></i><span class="menu-item" data-i18n="API">Descriptifs génériques</span></a> </li>
            <li [ngClass]="{'active': currentRoute.includes('/parameter')}" class="importantSeparation"><a routerLink="/parameters" class="sideBarLink" title="Liste des paramètres"><i class="ft-settings"></i><span class="menu-item">Paramètres</span></a> </li>
          </ul>
        </li>
      </ul>
      <div class="menu-back-bo-v2 ng-tns-c92-1">
        <a class="ng-tns-c92-1" (click)="goToBackOffice();"  title="Retour vers le Back-Office">
          <div class="menu-back-bo-btn-v2 ng-tns-c92-1">
            <img src="assets/img/back-office-icon.svg" alt="Retour vers le Back-Office" class="ng-tns-c92-1"> Mid-Office
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="sidebar-background"></div>
</div>
