import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../shared/services/products.service";
import {GlobalService} from "../shared/services/global.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  nbProduct = 0;
  nbProductArchived = 0;
  nbProductNotEdited = 0;
  nbPackage = 0;
  nbPackageArchived = 0;
  nbPackageNotEdited = 0;
  nbProduitsManquants = 0;
  nbPackageManquants = 0;
  constructor(private productService: ProductsService,private globalService: GlobalService,) {}
  ngOnInit(): void {
    console.log('========== env =======')
    console.log('ApiUrl ==================================================>',environment.ApiUrl);
    console.log('activeEnvironment ==================================================>',environment.activeEnvironment);
    console.log('======= end env ===========')
    this.GetProductNumber();
    this.GetProductArchivedNumber();
    this.GetPackageNumber();
    this.GetPackageArchivedNumber();
    this.GetCountProduitsManquants('accommodation');
    this.GetCountProduitsManquants('package');
  }
  GetProductNumber()
  {
    this.globalService.showSpinner();
    this.productService.getAllProducts('accommodation').subscribe(
      productsLists => {
        this.nbProduct =productsLists[0]['info'][0]['totalItems'];
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  GetProductArchivedNumber()
  {
    this.globalService.showSpinner();
    this.productService.searchArchivedProduct(1,null,null,'accommodation').subscribe(
      (productsLists:any) => {
        this.nbProductArchived =productsLists['total'];
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  GetPackageNumber()
  {
    this.productService.searchPackages(1,true,'','',null,null,'').subscribe(
      (packagesList:any) => {
        this.nbPackage=packagesList.total;
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  GetPackageArchivedNumber()
  {
    this.globalService.showSpinner();
    this.productService.searchArchivedProduct(1,null,null,'package').subscribe(
      (productsLists:any) => {
        this.nbPackageArchived =productsLists['total'];
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  GetCountProduitsManquants(type:string)
  {
    this.globalService.showSpinner();
    this.productService.getCountProduitsManquants(type).subscribe(
      (response:any) => {
        if (type=='accommodation')
        {
          this.nbProduitsManquants=response[0]['info'][0]['totalItems'];
        }
        if (type=='package')
        {
          this.nbPackageManquants=response[0]['info'][0]['totalItems'];
        }

      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }
}
