<div class="content-wrapper">
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Descriptifs génériques</h6></div>
            <div class="card-header">
              <h4 class="card-title float-left product-count">{{nbDescriptions}} descriptif<span *ngIf="nbDescriptions >1">s</span></h4>
              <a routerLink="/description/new/generic-descriptions" title="Nouveau hôtel">
                <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right" type="button">
                  <i class="ft-plus mr-1"></i>Nouveau descriptif générique  </button>
              </a>
            </div>
            <div *ngIf="genericDescriptionList" class="card-content ">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        <form #SearchEngineForm="ngForm" (ngSubmit)="searchDescriptions(SearchEngineForm,1);">
                          <!-- ligne 1 -->
                          <div class="row" style="display: flex!important;">
                            <!-- Nom -->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                  <label for="titre">Titre</label>
                                  <input class="form-control" id="titre" name="titre" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Pays-->
                            <div class="col-md-3">
                              <div class="form-floating mb-3">
                                <label>Pays</label>
                                <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                                <ng-select2 id="countrys"  name="countrys"  ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2" [width]="value"></ng-select2>
                              </div>
                            </div>
                            <!-- Ville-->
                            <div class="col-md-3">
                              <div class="form-floating mb-3">
                                <label>Villes</label>
                                <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                                <ng-select2 id="citys" name="citys"  ngModel  [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Villes'"  [data]="listOfCityForCountry" class="form-control ng-select2" [width]="value"></ng-select2>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label for="titre">&nbsp;</label>
                                <button class="btn btn-primary w-auto d-block" type="submit">
                                  <i class="ft-search mr-1"></i>Rechercher </button>
                              </div>
                            </div>

                          </div>
                        </form>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
                <div class="row mb-1">&nbsp;</div>
                <div class="table-responsive" *ngIf="genericDescriptionList?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row" matSort (matSortChange)="sortData($event)">
                    <thead >
                    <tr>
                      <th mat-sort-header="titleFr" style="width:16% !important;">Titre</th>
                      <th mat-sort-header="objectIdentity" style="width: 9% !important;">Pays</th>
                      <th mat-sort-header="objectIdentity" style="width: 9% !important;">ville</th>
                      <th mat-sort-header="textFr" style="width: auto !important;">Description</th>
                      <th mat-sort-header="createdAt" style="width: 12% !important;">Date de création</th>
                      <th style="width: 9% !important;">Actions</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="genericDescriptionList?.length != 0">
                    <tr *ngFor="let item of genericDescriptionList">
                      <td style="width: 16% !important;">{{item.titleFr}}</td>

                      <td style="width: 9% !important;" *ngIf="!item.localityParent">{{item.locality.nameFr}}</td>
                      <td style="width: 9% !important;" *ngIf="!item.localityParent">&nbsp;</td>

                      <td style="width: 9% !important;" *ngIf="item.localityParent">{{item.localityParent.nameFr}}</td>
                      <td style="width: 9% !important;" *ngIf="item.localityParent">{{item.locality.nameFr}}</td>


                      <td class="genericDescriptionList" [innerHTML]="item.textFr|safeHtml"></td>
                      <td style="width: 12% !important;"  class="text-center">{{item.createdAt| date :  "dd/MM/yyyy à HH:mm"}}</td>
                      <td *ngIf="item.oclub" style="width: 11% !important;">{{item.oclub.nameFr}}</td>
                      <td style="width:9% !important;"  class="text-center">
                        <a routerLink="/description/edit/generic-descriptions/{{item.id}}" ><i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin-right:10px; " title="Modifier"></i></a>
                        <i class="ft-x ml-1 cursor-pointer ft-delete-2 btnActionDelete" (click)="Delete(item);" title="Supprimer"></i>
                        <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(item.id);" data-toggle="modal" data-target="#editLogModal"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nbDescriptions >maxItem" >
                    <div align="right" class="row BlocPaginate">
                      <div class="pagination ngx-pagination">
                        <ngb-pagination [collectionSize]="nbDescriptions" (pageChange)="loadNextPage(SearchEngineForm,$event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                          <ng-template ngbPaginationPrevious>Précédent</ng-template>
                          <ng-template ngbPaginationNext>Suivant</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- Modal log -->
<div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <app-log-list [ElementId]="ElementId" [entityType]="'GenericDetail'"></app-log-list>
</div>
