<section id="login">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img" style="padding: 0;">
            <div class="row m-0">
              <div class="col-lg-12 auth-img-bg">
                <img  src="../assets/img/logo.png" alt="Maxula Travel" class="imgTopLogo">
              </div>
              <div class="col-lg-12 col-12 px-4 py-3">
                <h4 class="mb-2 card-title titleColor">Front Edito</h4>
                <p>Veuillez vous connecter à votre compte.</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                 <form  method="post" name="productNew" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm);">
                  <div class="form-group">
                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"  placeholder="Votre email" [ngClass]="{'is-invalid' : username.invalid  && (username.dirty || username.touched)}" #username="ngModel"   required minlength="8"  ngModel  class="form-control" id="username" name="username" >
                    <div *ngIf="loginFormSubmitted"
                         class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required</div>
                  </div>
                  <div class="form-group">
                    <input type="password" placeholder="Username" placeholder="Votre mot de passe" [ngClass]="{'is-invalid' : password.invalid  && (password.dirty || password.touched)}" #password="ngModel"   required minlength="4"  ngModel  class="form-control" id="password" name="password" >
                    <div *ngIf="loginFormSubmitted"
                         class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                    </div>
                  </div>
                  <hr>
                  <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                    <div class="remember-me mb-2 mb-sm-0">
                      <div class="checkbox auth-checkbox">
                        <!---input type="checkbox" formControlName="rememberMe" class="form-control" id="rememberMe">
                        <label for="rememberMe"><span class="font-small-2 mb-3 font-weight-normal">Remember Me</span></label--->
                      </div>
                    </div>
                    <!---a [routerLink]="['/pages/forgotpassword']">Mot de passe oublié??</a--->
                  </div>
                  <div class="d-flex justify-content-between flex-sm-row flex-column" style="float: right;">
                    <!---a [routerLink]="['/pages/register']" class="btn bg-light-primary mb-2 mb-sm-0">Register</a---->
                    <button type="submit" [disabled]="loginForm.invalid" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom">
                      <i class="fa fa-sign-in"></i> Connexion
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
