<nav class="navbar navbar-expand-lg navbar-light header-navbar navbar-fixed" id="headerBloc">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex" data-toggle="collapse" id="BtnOpenSideBar">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"><i class="ft-menu font-medium-5"></i></div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item" id="activeNotification" style="display: none;"><a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="dropdownBasic1" href="javascript:;" data-toggle="dropdown"><i class="ft-bell font-medium-3"></i><span id="headerNotification" class="notification badge badge-pill badge-danger"></span></a>
            <ul class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <a routerLink="/produits-manquants" style="text-decoration: none;color: #fff">
                    <div class="d-flex" style="color: #fff">
                      <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i>
                      <span class="noti-title">Ils y a des produits non saisis</span>
                    </div>
                  </a>
                  <span class="text-bold-400 cursor-pointer" id="closeProductNotification"><i class="ft-x-circle font-medium-3"></i></span>
                </div>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1"><a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2" href="javascript:;" data-toggle="dropdown">
            <div class="user d-md-flex d-none mr-2"><span class="text-right"><i class="fa-solid fa-user"></i> {{User_username}}</span></div></a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2">
              <a class="dropdown-item" (click)="logOut();">
                <div class="d-flex align-items-center"><i class="ft-power mr-2"></i><span>se déconnecter</span></div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
