<div class="content-wrapper">
  <div class="row"></div>
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Liste Produits non saisis</h6></div>
            <div *ngIf="productsLists" class="card-content">
              <div class="card-body">
                <div class="table-responsive" *ngIf="productsLists?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row mt-2 tableList" matSort (matSortChange)="sortData($event)">
                    <thead >
                    <tr>
                      <th mat-sort-header="code" style="width: 33% !important;">Référence</th>
                      <th style="width: 33% !important;">Importer produit</th>
                      <th style="width: 33%">Importer forfait</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="productsLists?.length == 0">
                    <img class="LoaderImages" src="../assets/img/load.gif" style="position: inherit; margin: 15px;">
                    </tbody>
                    <tbody *ngIf="productsLists?.length != 0 && productsLists.products?.length != 0">
                    <tr *ngFor="let item of productsLists.products">
                      <td style="width: 33% !important;">{{item.CODE}}</td>
                      <td style="width: 33% !important;"><button class="btn btn-info mr-1" style="width: 250px;" (click)="importer('product',item.CODE)"><i class="ft-arrow-down"></i>&nbsp;importer {{item.CODE}}</button> </td>
                      <td style="width: 33% !important;">
                        <span class="listOverFlow">
                          <li *ngFor="let package of item.PACKAGES">
                            <button class="btn btn-success mr-1 pckagesBtn" style="width: 250px;" (click)="importer('package',package.CODE)"><i class="ft-arrow-down"></i>&nbsp;importer {{package.CODE}}</button>
                          </li>
                        </span>

                      </td>
                    </tr>
                    </tbody>
                    <tbody *ngIf="productsLists?.length != 0 && productsLists.packages?.length != 0" style="border: 2px solid #40c057;">
                    <tr *ngFor="let item of productsLists.packages">
                      <td style="width: 33% !important;">{{item.CODE}}</td>
                      <td style="width: 33% !important;"></td>
                      <td style="width: 33% !important;">
                         <button class="btn btn-success mr-1 pckagesBtn" style="width: 250px;" (click)="importer('package',item.CODE)"><i class="ft-arrow-down"></i>&nbsp;importer {{item.CODE}}</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
