<div class="row">
  <div class="col-12">
    <img *ngIf="PackageFormules?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
    <div class="card" *ngIf="PackageFormules?.length != 0">
      <div class="card-header">
        <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">Formules</h4>
      </div>
      <div class="row">
        <div class=" mb-3 col-lg-2 ml-3" *ngFor="let item of PackageFormules">
          <input class="mr-1 cursor-pointer" type="checkbox" name="formules_{{item.code}}" value="{{item.code}}" id="formules_{{item.code}}"  [attr.checked]="PackageTagsCode.includes(item.code) ? 'checked': null"  (click)="onChange(item.id,item.code,item.nameFr,'formules')">
          <label class=""> {{item.nameFr}} </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <img *ngIf="PackageThemes?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
    <div class="card" *ngIf="PackageThemes?.length != 0">
      <div class="card-header">
        <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">Thèmes</h4>
      </div>
      <div class="row">
        <div class=" mb-3 col-lg-2 ml-3" *ngFor="let item of PackageThemes">
          <input class="mr-1 cursor-pointer" type="checkbox" name="themes_{{item.code}}" value="{{item.code}}" id="themes_{{item.code}}"  [attr.checked]="PackageTagsCode.includes(item.code) ? 'checked': null"  (click)="onChange(item.id,item.code,item.nameFr,'themes')">
          <label class=""> {{item.nameFr}} </label>
        </div>
      </div>
    </div>
  </div>
</div>
