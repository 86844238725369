import {Component, Input, OnInit} from '@angular/core';
import {ProductsService} from "../../../shared/services/products.service";
import {GlobalService} from "../../../shared/services/global.service";
import {FormControl, NgForm} from "@angular/forms";
import {debounceTime, map, tap} from "rxjs/operators";
import {Constantes} from "../../../shared/constantes";
import {NotificationService} from "../../../shared/services/notification.service";
@Component({
  selector: 'app-PackageGeneralites',
  templateUrl: './package.generalites.component.html',
  styleUrls: ['./package.generalites.component.css']
})
export class PackageGeneralitesComponent implements OnInit {
  readonly Constantes = Constantes;
  PackageSubTypes: any = [];
  PackagePensions: any = [];
  defaulDurationNights:any;
  defaulDurationDays:any;
  deletePackageSegmentStatus:boolean=false;
  parentCode = 'package';
  GeneralitesForm = new FormControl();
  addNew:boolean=false;
  selectedPackageProducts: any = [];
  finalPackageProducts: any = [];
  products: any = [];
  formControl = new FormControl();
  value = 'resolve';
  allowClear = true;
  required = true;
  disabled = false;
  productName = 'fra';
  isLoading = false;
  filteredProducts: any;
  errorMsg: string = '';
  defaultMealPlan=null;
  searchProductName =  new FormControl();
  bodyInvalid:boolean=false;
  /************************** CKEDITOR  *******************************/
  CKEDITOR: any;
  /************************** CKEDITOR  *******************************/
  constructor(
    private notification: NotificationService,
    private productService: ProductsService,
    private globalService: GlobalService) { }

  @Input() packageCode: string = '';
  @Input() packageId: number = 0;
  @Input() Package: any = [];
  @Input() existedPackageProducts: any = [];


  ngOnInit(): void
  {
    /************************** CKEDITOR  *******************************/
    delete CKEDITOR.instances["#body"];
    CKEDITOR.replace('body', {
      customConfig : '/assets/js/cke_custom_basic_dev_config_full.js',
      height : '350px'
    });
    /************************** CKEDITOR  *******************************/
    this.getPackagePensions();
    this.getPackageSubTypes();
    this.getproductsByName();
    /************************** CKEDITOR  *******************************/
    CKEDITOR.instances["body"].setData(this.Package.introduction);
    /************************** CKEDITOR  *******************************/


  }
  getPackagePensions() {
    this.globalService.getParameterValue('', 'meal_plan_type')
      .subscribe(
        PackagePensions => {
          for (let item in PackagePensions[0]['data']) {
            this.PackagePensions.push(PackagePensions[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageSubTypes() {
    this.globalService.getParameterValue(this.parentCode, 'product_subtype')
      .subscribe(
        PackageSubTypes => {
          for (let item in PackageSubTypes[0]['data']) {
            this.PackageSubTypes.push(PackageSubTypes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getproductsByName() {
    this.searchProductName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredProducts = [];
          this.isLoading = true;
        }),
        map(productNameFr => productNameFr.toString().trim())
      )
      .subscribe((productCODE: string) => {
        this.productService.searchProductsCodeName(productCODE,'accommodation').subscribe(
            searchResponse => {
              if (searchResponse) {
                this.isLoading = false;
                this.products = searchResponse.map(function(row:any) {
                  return { id : row.id, code : row.code, nameFr : row.namefr }
                })
              }
            },
            error => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }

  /* save product on select*/
  productsOptionSelected(product: any): boolean {
    let newCode='';
    /********* delete doublons if existe ***********/
    for (let item in this.existedPackageProducts)
    {
      if (this.existedPackageProducts[item]['code']==product['code'])
      {
        this.existedPackageProducts.splice(item, 1);
      }
    }
    /********** save new product on first element of  existedPackageProducts *********/
    this.existedPackageProducts.unshift(product);
    newCode=product['code'].toString().trim();
    /****** empty uatosuggest new product **********/
    if (event && this.searchProductName) {
      let newProducts: any = [];
      $(".autocompleteInput").val('');
    }
    /**************** set border color green  for new product *********/
    setTimeout(function(){ $('#newProductBadge_'+newCode).parent().addClass('newAdd') }, 400);
    return true;
  }
  removeThisProduct(code: string) {

    for (let i in this.existedPackageProducts) {
      if (this.existedPackageProducts[i]['code'] == code) {
        this.existedPackageProducts.splice(i, 1);
        break;
      }
    }
  }
  save() {
    /********  save all this product products on same list (existed  + new selected) ********
    for (let val of this.existedPackageProducts) {
      if (!this.finalPackageProducts.includes(val)) {
        this.finalPackageProducts.push(val);
      }
    }
    //  save all this product products on same list (existed  + new selected) ********
    console.table('existedPackageProducts', this.existedPackageProducts);
    console.table('finalPackageProducts', this.finalPackageProducts);

    //autocompleteInput
    ***/
  }
  productDisplayFormat(product: any): string {
    if (product) {
      return product.nameFr + ' [' + product.code + ']';
    } else {
      return '';
    }
  }
  addNewProduct()
  {
    this.addNew=true;
  }
  hideNewProduct() {
    this.addNew=false;
  }
  onSubmit(form:NgForm)
  {
    this.globalService.showSpinner();
    let bodyContent = CKEDITOR.instances["body"].getData();
    let entity='product';
   /* if (bodyContent && bodyContent.length >0 && bodyContent.length < 3)
    {
      this.bodyInvalid = true;
    }
    else
    {
      this.bodyInvalid = false;
    }*/

    if(form.valid )
    {
      let nameFr = form.value.name;
      nameFr=nameFr.trim();
      let code = form.value.code;
      //let subTypeId=$("#sub_type option:selected").attr("id");
      let subTypeId=null
      //let pensionId=$("#pensions option:selected").attr("id");
      let pensionId=null;
      let durationDays=form.value.durationDays;
      let durationNights=form.value.durationNights;
      let typeId=localStorage.getItem('package');
      let isActive =false;
      if ($("#product_active").prop('checked'))
      {
        isActive=true;
      }
      else
      {
        isActive=false;
      }
      if (this.packageId && typeId!=null)
      {
        this.productService.addEditProductGeneralities(nameFr,code,null,null,null,null,typeId,subTypeId,pensionId,this.packageId,isActive,null,null,null,bodyContent,durationDays,durationNights)
          .subscribe(
            response =>
            {console.log(response);
              if (!isNaN(response['id']))
              {
                /***********************  if update product is OK : delete all this Product Localities and add it ****************************************/
                this.editPackageSegment();
                /***********************  display confirmation alert ***************************/
              }
            },
            error => {
              console.log('error ==>',error);
              this.globalService.hideSpinner();
              this.notification.showError(error['error']['hydra:description'],"");
            }
          );
      }
      //autocompleteInput
    }
  }

  editPackageSegment()
  {
    let This=this;
    let updatedPackageSegment=[];
    if(this.existedPackageProducts.length>0 && this.packageId)
    {
      for (let item = 0; item <= this.existedPackageProducts.length-1; item++)
      {
        updatedPackageSegment.push(this.existedPackageProducts[item]['id']);
      }
    }
        this.notification.hideNotification();
    console.log('editPackageSegment = ',updatedPackageSegment);
        this.productService.editPackageSegment(updatedPackageSegment,this.packageId)
          .subscribe(
            response =>
            {
              this.globalService.hideSpinner();
              this.notification.hideNotification();
                setTimeout(function () {
                  This.notification.showSuccess("La modification a été faite avec succès",'');
                }, 500);
            },
            error => {
              console.log('add Product Locality error ==>',error);
              this.globalService.hideSpinner();
              this.notification.showError(error['error']['hydra:description'],"");
            }
          );


  }

  durationTransformFormat(event: any)
  {

    let duration=event.target.value;
    if(!isNaN(duration) && duration > 0 && duration < 10)
    {
      let newVal = '0' + duration;

      if (event.target.name=="durationNights")
      {
        this.defaulDurationNights=newVal;
      }
      if (event.target.name=="durationDays")
      {
        this.defaulDurationDays=newVal;
      }

    }
  }
}
