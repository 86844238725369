<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-sm-flex align-items-center justify-content-between infoBar">
            <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
              <span class="badge badge-soft-dark" *ngIf="type && type=='generic-descriptions'">Nouveau descriptif générique</span>
              <span class="badge badge-soft-dark" *ngIf="type && type!='generic-descriptions'">Nouveau descriptif  {{Product.code}} {{Product.nameFr}}</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <div class="mb-4">
                <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle" *ngIf="title.value?.length>0" [innerHTML]="title.value"></h4>
              </div>
            </div>
            <div class="card desciptionCard">
              <form method="post" name="objectDetails" #objectDetailsForm="ngForm" (ngSubmit)="onSubmit(objectDetailsForm);">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="title">Titre<span class="etoile">*</span></label>
                      <input type="text" [ngClass]="{'is-invalid' : title.invalid  && (title.dirty || title.touched)}" #title="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  class="form-control" id="title" name="title" placeholder="titre">
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-2">
                    <label class="form-label" for="channels">Canal</label>
                    <div class="mb-3">
                      <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <select  #channelID="ngModel" (change)="getProductFamillesDetail(channelID.value)"  [(ngModel)]="channelId >0 && channelId || defaultChannel" aria-label="Floating label select example" class="form-control" id="channels" name="channels">
                        <option *ngFor="let channel of channels" class="textCapitalise"  value="{{channel.id}}">{{channel.nameFr}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-3">
                    <div class="mb-3">
                      <label for="family">Famille</label>
                      <ng-select2   [ngClass]="{'is-invalid' : family.invalid  && (family.dirty || family.touched)}"  #family="ngModel"   ngModel id="family"  name="family"  *ngIf="productFamillesDetail?.length != 0" [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Famille'" [data]="productFamillesDetail" class="form-control ng-select2 textCapitalise" [width]="value" ></ng-select2>
                    </div>
                  </div>
                  <div *ngIf="type && type !='generic-descriptions'" class="col-lg-1">
                    <div class="mb-3">
                      <label for="rank" style="visibility: hidden">Ordre</label>
                      <input type="number" class="form-control" name="rank" id="rank" ngModel style="visibility: hidden">
                    </div>
                  </div>
                  <!-- Pays-->
                  <div class="col-md-2" *ngIf="type && type =='generic-descriptions'" >
                    <div class="form-floating mb-3">
                      <label>Pays<span class="etoile">*</span></label>
                      <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <ng-select2 id="countrys"  name="countrys"  ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled" [required]="required" [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2" [width]="value"></ng-select2>
                    </div>
                  </div>
                  <!-- Ville-->
                  <div class="col-md-2" *ngIf="type && type =='generic-descriptions'" >
                    <div class="form-floating mb-3">
                      <label>Villes</label>
                      <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                      <ng-select2 id="citys" name="citys"  ngModel  [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Villes'"  [data]="listOfCityForCountry" class="form-control ng-select2" [width]="value"></ng-select2>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="mb-3">
                      <label >Description<span class="etoile">*</span></label>
                      <div [ngClass]="{'isInvalid' : bodyInvalid}">
                        <textarea ngModel  id="bodyNew" name="bodyNew"  placeholder="Description..."></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 float-end">
                  <div class="d-flex flex-wrap gap-2 justify-content-end">
                    <button (click)="goBack()" class="btn btn-info waves-effect btn-label waves-light modal-wait blocRightBottom mr-2" type="button">
                      <i class="ft-arrow-left"></i> Annuler
                    </button>
                    <button class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" [disabled]="objectDetailsForm.invalid || productFamillesDetail?.length == 0" type="submit">
                      <i class="ft-save"></i> Enregistrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
