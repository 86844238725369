import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../../shared/services/products.service";
import {NgForm,FormControl} from '@angular/forms';
import {debounceTime, tap, map } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
@Component({
  selector: 'app-Package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.css']
})
export class PackageListComponent implements OnInit {
  nbPackages=0;
  firstPage=0;
  nextPage=0;
  lastPage=0;
  page: number = 1;
  packagesList: any = [];
  ElementId:number =0;
  packagesNameLists: any = [];
  localitiesOfAllPackages :any = [];
  PackageSubTypes: any = [];
  searchPackageName = new FormControl();
  filteredPackages: any;
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  currentPage=1;
  parentCode='package';
  maxItem=Constantes.maxItemsPerPage;
  constructor(private productService: ProductsService,private globalService: GlobalService,private notification: NotificationService) {}
  ngOnInit(): void {
    this.getAllpackages();
    this.getLocalitiesOfAllPackages()
    this.getPackageSubTypes();
    this.GetPackageName ();
  }
  getAllpackages()
  {
    this.globalService.showSpinner();
    this.productService.searchPackages(1,true,'','',null,null,'').subscribe(
      (packagesList:any) => {
        this.packagesList = packagesList['data'];console.log('*****************   this.packagesList  ==  ',this.packagesList);
        this.nbPackages=0;
        this.lastPage=0;
        this.nbPackages =packagesList['total'];
        this.lastPage=Math.ceil(this.nbPackages/this.maxItem);
        this.globalService.hideSpinner();
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  displayFn: any;
  getLocalitiesOfAllPackages() {
    this.productService.getProductLocalities()
      .subscribe(
        Localities => { console.log('==> Localities ==>  ',Localities);
          this.localitiesOfAllPackages.push({'id':'','text':'Pays / ville'});
          for (let countryItem in Localities)
          {
            if (Localities[countryItem]['type_code']== 'country')
            {
              this.localitiesOfAllPackages.push({'id':Localities[countryItem]['code'],'text':Localities[countryItem]['name_fr']});
              for (let cityItem in Localities)
              {
                /*** get all city of  current country****/
                if ( (Localities[cityItem]['type_code']== 'city') && (Localities[cityItem]['parent_code'] == Localities[countryItem]['code']) )
                {
                  this.localitiesOfAllPackages.push({'id':Localities[cityItem]['code'],'text':"    - "+Localities[cityItem]['name_fr']});
                }
              }
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageSubTypes() {
    this.globalService.getParameterValue (this.parentCode,'product_subtype')
      .subscribe(
        PackageSubTypes => {
          for (let item in PackageSubTypes[0]['data']) {
            this.PackageSubTypes.push(PackageSubTypes[0]['data'][item]);
          }
        },
        (error:any) => {
          this.globalService.checkApiError(error);
        }
      );
  }
  GetPackageName ()
  {
    this.searchPackageName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredPackages = [];
          this.isLoading = true;
        }),
        map((productNameFr: string) =>productNameFr.toString().trim())
      )
      .subscribe((productNameFr:string) => {
          this.productService.searchProductsName(productNameFr,'package').subscribe(
            (searchResponse:any) =>
            {
              if (searchResponse[0]!== undefined)
              {
                this.isLoading = false;
                this.packagesNameLists=searchResponse[0]['data'];
              }
            },
            (error:any) => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }
  searchPackages(form: NgForm,page=1)
  {
    this.globalService.showSpinner();
    let code=form.value.code;
    let name=this.searchPackageName.value;
    let etat=form.value.etat;
    let locality=form.value.Localitys;
    let codeHotel=form.value.codeHotel;
    this.packagesList = [];
    this.productService.searchPackages(page,true,name,code,codeHotel,locality,etat).subscribe(
      (searchResponse:any) =>
      {
        this.packagesList=searchResponse['data'];
        this.lastPage=0;
        this.nbPackages=0;
        this.nbPackages =searchResponse['total'];
        this.lastPage=Math.ceil(this.nbPackages/Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.packagesList.slice();
    if (!sort.active || sort.direction === '') {
      this.packagesList= data;
      return;
    }

    this.packagesList= data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'nameFr':
          return this.compare(a.nameFr, b.nameFr, isAsc);
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        case 'localities':
          return this.compare(a.localities, b.localities, isAsc);
        case 'durationNights':
          return this.compare(a.duration_nights, b.duration_nights, isAsc);
        case 'active':
          return this.compare(a.is_active, b.is_active, isAsc);
        default:
          return 0;
      }
    });
  }
  pagesCounter(i: number) {
    return new Array(i);
  }
  loadNextPage(form: NgForm,pageNumber:number)
  {
    this.currentPage=pageNumber;
    if (pageNumber<=this.lastPage && pageNumber >0)
    {
      this.searchPackages(form,pageNumber);
    }
  }
  duplicateProduct(item:any)
  {
    let This=this;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir dupliquer",item.name,"Continuer","Dupliquer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        /*************************** code produit à dupliquer *******************************/
        $('#duplicationPackageModal').show();
        $('#duplicationPackageModal').addClass('show');
        $("#btnDiplicatePackageCode").click(function(){
          $('#duplicationPackageModal').hide();
          $('#duplicationPackageModal').removeClass('show');
          let newDuplicatPackageCode:any=$('#newDuplicatPackageCode').val();
          if (newDuplicatPackageCode.length >3 )
          {
            /************************** TO DO ***********************************************/
             This.globalService.showSpinner();
             This.productService.duplicateProduct(item.id,newDuplicatPackageCode).subscribe(
          response => {
            console.log('duplicate response id',response);
            This.globalService.hideSpinner();
            if (response.message=='OK')
            {
                This.notification.showSuccess("Le forfait ' "+item.name+" ' a été dupliqué avec succès","");
                This.getAllpackages();
                let editUrl="/package/"+response.data.id+"/"+response.data.code+"/generalites/edit";
                setTimeout(function()
                {
                  $("html, body").animate({ scrollTop: 0 }, "slow");
                }, 2200);
                setTimeout(function()
                {
                  window.location.href=editUrl;
                }, 2200);
            }
            else
            {
              This.notification.showError(response.message,"");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            This.globalService.hideSpinner();
            This.globalService.checkApiError(error);
            This.globalService.hideSpinner();
            This.notification.showError(error['error']['hydra:description'],"");
          }
        );
            /************************** TO DO ***********************************************/
          }
        });
        /*************************** code produit à dupliquer *******************************/
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  EditProductArchived(product:any)
  {
    let This=this;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir archiver ",product.name,"Valider","Archiver !","", function (confirmed:any)
    {
      if (confirmed)
      {
        This.globalService.showSpinner();
        This.productService.EditProductArchived(product.id).subscribe(
          response => {
            console.log('archivated  response',response);
            This.globalService.hideSpinner();
            if (response['id'])
            {
              This.notification.showSuccess("Le forfait ' "+product.name+" ' a été archivé avec succès","");
              This.getAllpackages();
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            This.globalService.hideSpinner();
            This.globalService.checkApiError(error);
            This.globalService.hideSpinner();
            This.notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
  closeModalDuplicateModal()
  {
    /**************** hide Duplicated product Modal************************/
    console.log('click');
    $('.modalDuplicate').hide();
    $('.modalDuplicate').removeClass('show');
    /**************** hide Duplicated product Modal************************/
  }
}


